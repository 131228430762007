import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { EffortComponent } from "../bottom-sheet/EffortComponent";
import { useStores } from "../../models";
import { Sport } from "../../models/sport/sport";
import { MinPerHundredMeterComponent } from "../bottom-sheet/MinPerHundredMeterComponent";
import { View } from "react-native";
import { spacing } from "../../theme";
import { MoreOptions } from "../../screens/run/MoreOptions";
import { ObjectiveModeSegmented } from "../segmented/ObjectiveModeSegmented";
import { RaceType } from "../../models/race/race-type";

export const SwimComponent = observer(function SwimComponent({
  hideMoreOptions,
}: {
  hideMoreOptions: boolean;
}) {
  const { globalStore } = useStores();
  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(RaceType.Swim),
    [],
  );

  return (
    <View style={{ gap: spacing.medium }}>
      {hideMoreOptions ? null : <ObjectiveModeSegmented sport={sport} />}

      <MinPerHundredMeterComponent sport={sport} />

      <EffortComponent
        effort={sport.effort}
        onEffortChange={(e) => sport.updateEffort(e)}
      />

      {hideMoreOptions ? null : <MoreOptions type={RaceType.Swim} />}
    </View>
  );
});
