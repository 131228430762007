import { observer } from "mobx-react-lite";
import { RaceType } from "../../models/race/race-type";
import { RunComponent } from "./run-component";
import { BikeComponent } from "./bike-component";
import { SwimComponent } from "./swim-component";
import { Timeline } from "./triathlon/Timeline";
import React from "react";

export const SportsComponent = observer(function SportsComponent({
  type,
  setType,
  hideMoreOptions,
}: {
  type: RaceType | "all";
  setType: (type: RaceType | "all") => void;
  hideMoreOptions: boolean;
}) {
  return (
    <>
      {type === RaceType.Run && (
        <RunComponent hideMoreOptions={hideMoreOptions} />
      )}
      {type === RaceType.Bike && (
        <BikeComponent hideMoreOptions={hideMoreOptions} />
      )}
      {type === RaceType.Swim && (
        <SwimComponent hideMoreOptions={hideMoreOptions} />
      )}
      {type === "all" && <Timeline onChangeType={(t) => setType(t)} />}
    </>
  );
});
