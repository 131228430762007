import React, { FC, useState } from "react";
import { FlatList, Platform, ScrollView, View } from "react-native";
import { observer } from "mobx-react-lite";
import {
  CENTER_MIDDLE,
  CONTAINER,
  SMALL_BUTTON,
  SMALL_BUTTON_TEXT,
} from "../theme/view-style";
import { Button, Footer, Screen, Text, TextField } from "../components";
import { useOrientation } from "../utils/orientation-style";
import { palette, spacing } from "../theme";
import { AppStackParamList, AppStackScreenProps } from "../navigators";
import { useStores } from "../models";
import { useBrandTheme } from "../theme/use-brand-theme";
import { Race } from "../models/race/race";
import { translate } from "swunitch-i18n";
import { RaceCalculator } from "../components/race-calculator/RaceCalculator";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Slider } from "@miblanchard/react-native-slider";
import { useDebounceEffect } from "ahooks";
import { SportSelector } from "../components/SportSelector";
import { RaceType } from "../models/race/race-type";
import { openUrl } from "../services/openUrl";
import { StackNavigationProp } from "@react-navigation/stack";

export const RacesScreen: FC<AppStackScreenProps<"races">> = observer(
  function RacesScreen({ route }) {
    const { globalStore } = useStores();
    const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
    const givenRaces: Array<Race> = route.params?.races || [];
    const search = globalStore.search;
    const deviceOrientation = useOrientation();
    const theme = useBrandTheme();
    const races = givenRaces.length ? givenRaces : globalStore.searchedRaces;
    const [refreshing, setRefreshing] = useState(false);

    useDebounceEffect(
      () => {
        search.fetchLocations(
          search.searchedLocation || "",
          globalStore.latitude,
          globalStore.longitude,
        );
      },
      [search.searchedLocation],
      { wait: 500 },
    );

    useDebounceEffect(
      () => {
        if (givenRaces.length) {
          return;
        }

        setRefreshing(true);
        globalStore.fetchSearch().finally(() => setRefreshing(false));
      },
      [
        search.title,
        search.distance,
        search.type,
        search.latitude,
        search.longitude,
      ],
      {
        wait: 500,
      },
    );

    return (
      <Screen preset="fixed">
        <FlatList
          refreshing={refreshing}
          data={races}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <RaceCalculator key={item.id} race={item} computeTime={false} />
          )}
          ListFooterComponent={<Footer />}
          ListHeaderComponent={
            givenRaces.length ? null : (
              <View
                style={{
                  ...CONTAINER(deviceOrientation),
                  marginTop: spacing.small,
                }}
              >
                <View style={{ ...CENTER_MIDDLE, gap: spacing.tiny }}>
                  <TextField
                    value={search.title || undefined}
                    onChangeText={(text) =>
                      search.updateSearch({ title: text })
                    }
                    placeholderTx="common.search"
                    clearButtonMode="always"
                    containerStyle={{ flex: 1 }}
                  />
                  {search.nbOfFilters ? (
                    <Button
                      onPress={() => search.reset()}
                      preset="reversed"
                      tx="common.reset"
                    />
                  ) : null}
                </View>

                <Text
                  preset="formLabel"
                  tx="racesScreen.addRace"
                  onPress={() => {
                    const helpUrl = "https://pacevisor.com/new?nav=false";
                    if (Platform.OS === "web") {
                      openUrl(helpUrl);
                    } else {
                      navigation.navigate("help", {
                        url: helpUrl,
                        title: translate("new.addRace"),
                      });
                    }
                  }}
                  style={{ ...SMALL_BUTTON_TEXT, textAlign: "center" }}
                />

                <Text preset="subheading">{translate("sports.title")}</Text>
                <SportSelector
                  type={search.type as RaceType}
                  onChange={(t) => {
                    search.updateSearch(
                      t === search.type ? { type: null } : { type: t },
                    );
                  }}
                />

                <View style={{ marginTop: spacing.small }}>
                  <TextField
                    label={translate("racesScreen.location")}
                    LabelTextProps={{ preset: "subheading" }}
                    value={search.searchedLocation || undefined}
                    onChangeText={(text) =>
                      search.updateSearch({
                        searchedLocation: text,
                        latitude: null,
                        longitude: null,
                      })
                    }
                    placeholderTx="racesScreen.location"
                    clearButtonMode="always"
                  />

                  <ScrollView
                    horizontal={true}
                    style={{ marginTop: spacing.extraSmall }}
                  >
                    {search.searchedLocations.map((location) => {
                      const isSelected =
                        search.latitude === location.latitude &&
                        search.longitude === location.longitude;
                      return (
                        <Button
                          key={location.name}
                          preset={isSelected ? "reversed" : "outlined"}
                          style={{
                            ...SMALL_BUTTON,
                            marginRight: spacing.small,
                          }}
                          textStyle={SMALL_BUTTON_TEXT}
                          text={location.name}
                          onPress={() =>
                            isSelected
                              ? search.updateSearch({
                                  latitude: null,
                                  longitude: null,
                                })
                              : search.updateSearch({
                                  latitude: location.latitude,
                                  longitude: location.longitude,
                                })
                          }
                          RightAccessory={() =>
                            isSelected ? (
                              <MaterialCommunityIcons
                                name="close-circle"
                                size={15}
                                color={palette.neutral100}
                                style={{ marginLeft: spacing.tiny }}
                              />
                            ) : null
                          }
                        />
                      );
                    })}
                  </ScrollView>
                </View>

                <View style={{ ...CENTER_MIDDLE, marginTop: spacing.medium }}>
                  <Text preset="subheading">
                    {translate("racesScreen.distance", {
                      count: search.distance || 0,
                    })}
                  </Text>

                  {search.distance ? (
                    <MaterialCommunityIcons
                      name="close-circle"
                      size={18}
                      color={theme.colors.text}
                      style={{ marginLeft: spacing.small }}
                      onPress={() => search.updateSearch({ distance: null })}
                    />
                  ) : null}
                </View>

                <Slider
                  containerStyle={{ height: 30 }}
                  minimumValue={0}
                  maximumValue={200}
                  trackClickable={true}
                  step={1}
                  value={search.distance || undefined}
                  onValueChange={(v) => {
                    if (v[0] !== undefined) {
                      search.updateSearch({ distance: v[0] });
                    }
                  }}
                  minimumTrackTintColor={palette.secondary400}
                  // @ts-ignore
                  testID="slider"
                />
              </View>
            )
          }
        />
      </Screen>
    );
  },
);
