import { Platform, TextStyle, ViewStyle } from "react-native";
import { spacing } from "./spacing";
import {
  DEVICE_ORIENTATION,
  landscapeStyle,
  portraitStyle,
} from "../utils/orientation-style";
import { typography } from "./typography";
import { palette } from "./palette";
import { BrandTheme } from "./use-brand-theme";

export const PRIMARY_BOLD_FONT: TextStyle = {
  fontFamily: typography.title.bold,
};

export const PRIMARY_SEMI_BOLD_FONT: TextStyle = {
  fontFamily: typography.title.semiBold,
};

export const PRIMARY_FONT: TextStyle = {
  fontFamily: typography.title.normal,
};

export const CONTAINER = function (
  deviceOrientation: DEVICE_ORIENTATION,
): ViewStyle {
  return {
    ...portraitStyle(deviceOrientation)({
      paddingHorizontal: spacing.medium,
    }),
    ...landscapeStyle(deviceOrientation)({
      paddingHorizontal: spacing.extraLarge,
    }),
  };
};

export const BACKGROUND_CONTAINER = function (theme: BrandTheme): ViewStyle {
  return {
    backgroundColor: theme.colors.backgroundApp,
    paddingBottom: spacing.medium,
  };
};

export const CARD_MARGIN = spacing.medium * 2;

export const CARD_CONTAINER = function (theme: BrandTheme): ViewStyle {
  return {
    backgroundColor: theme.colors.backgroundPrimary,
    margin: CARD_MARGIN / 2,
    marginBottom: 0,
    borderRadius: 15,
    shadowColor: theme.dark ? palette.neutral600 : palette.neutral300,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 15,
    elevation: 20,
  };
};

export const CARD = function (_theme: BrandTheme): ViewStyle {
  return {
    borderRadius: 15,
    overflow: "hidden",
  };
};

export const BOLD: TextStyle = {
  fontFamily: typography.primary.bold,
};

export const PRIMARY_TEXT = (theme: BrandTheme): TextStyle => ({
  ...BOLD,
  ...PRIMARY_FONT,
  color: theme.colors.primary,
});

export const BOTTOM_SHEET_TEXT: TextStyle = {
  color: palette.neutral100,
};

export const BOTTOM_SHEET_UNIT_TEXT: TextStyle = {
  ...BOTTOM_SHEET_TEXT,
  ...PRIMARY_FONT,
  alignSelf: "flex-end", // NOTE: for time unit d, h, m, s
};

const INPUT: TextStyle = {
  borderRadius: 10,
  padding: spacing.tiny,
  marginRight: spacing.tiny,
  fontFamily: typography.title.normal,
};

export const INPUT_SHADOW: ViewStyle = {
  ...INPUT,
  backgroundColor: palette.neutral100 + "66",
};

export const INPUT_SELECTED: TextStyle = {
  backgroundColor: palette.neutral100 + "22",
};

export const BIG_INPUT = (_theme: BrandTheme): ViewStyle & TextStyle => ({
  ...INPUT_SHADOW,
  flexDirection: "row",
  alignItems: "center",
  height: 50,
});

export const BIG_INPUT_TEXT: TextStyle = {
  ...BOTTOM_SHEET_TEXT,
  fontSize: 35,
  lineHeight: 45,
  ...PRIMARY_SEMI_BOLD_FONT,
};

export const BIG_CONVERTER: TextStyle = {
  ...BIG_INPUT_TEXT,
  height: 60,
  maxWidth: 150,
  ...(Platform.OS === "ios" ? { marginTop: 5 } : {}),
};

export const INPUT_SMALL = (theme: BrandTheme): TextStyle => ({
  ...INPUT,
  minWidth: 30,
  backgroundColor: theme.colors.backgroundInput,
  color: theme.colors.text,
  fontSize: 18,
  fontWeight: "normal",
  maxWidth: 70,
  marginRight: 0,
});

export const UNIT_DISPOSITION: ViewStyle = {
  flexDirection: "row",
  alignItems: "flex-end",
};

export const RACE_LINE_CONTAINER: ViewStyle = {
  flexDirection: "column",
  minHeight: 70,
};

export const CENTER_MIDDLE: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
};

export const RACE_COLUMN: TextStyle = {
  flex: 2,
  flexDirection: "row",
};

export const RACE_SMALL_COLUMN: TextStyle = {
  ...RACE_COLUMN,
  flex: 1.6,
};

export const RACE_TITLE: TextStyle = {
  ...PRIMARY_SEMI_BOLD_FONT,
  lineHeight: 18,
};

export const RACE_OPTIONS: TextStyle = {
  flex: 1,
  flexDirection: "row-reverse",
};

export const SMALL_BUTTON: ViewStyle = {
  alignSelf: "center",
  paddingVertical: spacing.micro,
  minHeight: 0,
};

export const SMALL_BUTTON_TEXT: TextStyle = {
  fontSize: 12,
};
