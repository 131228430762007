import React, { useState } from "react";
import { View } from "react-native";
import {
  CENTER_MIDDLE,
  CONTAINER,
  RACE_COLUMN,
  RACE_OPTIONS,
  SMALL_BUTTON,
  SMALL_BUTTON_TEXT,
} from "../../theme/view-style";
import { SportTimeEdition } from "./SportTimeEdition";
import { DistanceEdition } from "./DistanceEdition";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { Sport } from "../../models/sport/sport";
import { observer } from "mobx-react-lite";
import { DistanceModelInterface } from "../../models/distance/distance";
import { DistanceThumbnail } from "./DistanceThumbnail";
import { Distance } from "../../domain/race-calculator/Distance";
import { Text } from "../Text";
import { useOrientation } from "../../utils/orientation-style";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { palette, spacing } from "../../theme";
import { Vma } from "../../services/vma";
import { Tag } from "../Tag";
import { formatNumber } from "../../utils/formatNumber";
import { useScreen } from "../ScreenRefContext";
import { RaceType } from "../../models/race/race-type";
import { Button } from "../Button";

interface CustomDistanceCalculatorsProps {
  sport: Sport;
}

interface CustomDistanceCalculatorProps {
  sport: Sport;
  addCalculator?: () => void;
  removeCalculator?: () => void;
  canRemove: boolean;
  canAdd?: boolean;
  distance: DistanceModelInterface;
}

export const CustomDistanceCalculator = observer(
  function CustomDistanceCalculator(props: CustomDistanceCalculatorProps) {
    const {
      distance,
      sport,
      addCalculator = () => null,
      removeCalculator = () => null,
      canRemove,
      canAdd = true,
    } = props;
    const theme = useBrandTheme();
    const [hideOptions, setHideOptions] = useState(false);
    const { scrollToTop } = useScreen();

    const handleDistanceChange = (d: Distance) => {
      distance.updateValue(d);
    };

    const vmaPercent = formatNumber(
      Vma.getPercentFromDistance(
        sport.speed,
        distance.domainDistance.getInSmallSize(),
      ),
      0,
    );

    return (
      <View>
        <DistanceThumbnail
          {...(canRemove ? { onClose: removeCalculator } : {})}
          distanceContent={
            <DistanceEdition
              distance={distance.domainDistance}
              onDistanceChange={handleDistanceChange}
            />
          }
          content={
            <>
              <View style={RACE_COLUMN}>
                <SportTimeEdition
                  distance={distance.domainDistance}
                  sport={sport}
                  onEdition={(isInEdition) => setHideOptions(isInEdition)}
                />
              </View>
              <View style={RACE_OPTIONS}>
                {hideOptions ? null : (
                  <>
                    {sport.type === ("notyet" as RaceType.Run) && (
                      <Tag
                        onPress={() => {
                          sport.updateEffort(Number(vmaPercent));
                          scrollToTop();
                        }}
                        style={{
                          alignSelf: "center",
                          marginRight: spacing.extraSmall,
                        }}
                      >
                        <Text style={{ color: palette.neutral100 }}>
                          {vmaPercent}%
                        </Text>
                      </Tag>
                    )}
                  </>
                )}
              </View>
            </>
          }
        />
        {canAdd && (
          <Button
            onPress={addCalculator}
            style={{
              alignSelf: "center",
              ...SMALL_BUTTON,
              marginTop: spacing.small,
            }}
            preset="outlined"
            RightAccessory={() => (
              <MaterialCommunityIcons
                name="plus-circle"
                size={24}
                color={theme.colors.primary}
              />
            )}
            tx="trainingScreen.addDistance"
          />
        )}
      </View>
    );
  },
);

export const CustomDistanceCalculators = observer(
  function CustomDistanceCalculators(props: CustomDistanceCalculatorsProps) {
    const { sport } = props;
    const canRemoveIfMoreThanOne = sport.distances.length !== 1;
    const deviceOrientation = useOrientation();
    const [isInAddMode, setIsInAddMode] = useState(false);
    const theme = useBrandTheme();

    return (
      <View>
        <View
          style={{
            ...CONTAINER(deviceOrientation),
            ...CENTER_MIDDLE,
            justifyContent: "space-between",
          }}
        >
          <Text preset="heading" tx="trainingScreen.myDistances" />

          <Button
            style={{ ...SMALL_BUTTON, borderColor: theme.colors.primary }}
            textStyle={[SMALL_BUTTON_TEXT, { color: theme.colors.primary }]}
            preset="outlined"
            onPress={() => setIsInAddMode(!isInAddMode)}
            tx="trainingScreen.addDistanceButton"
          />
        </View>

        {sport.distances.map((distance) => (
          <CustomDistanceCalculator
            key={distance.id}
            distance={distance}
            sport={sport}
            canAdd={isInAddMode}
            canRemove={canRemoveIfMoreThanOne}
            addCalculator={() => {
              sport.addDistances(distance.id);
              setIsInAddMode(false);
            }}
            removeCalculator={() => sport.removeDistances(distance.id)}
          />
        ))}
      </View>
    );
  },
);
