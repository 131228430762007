import React from "react";
import { View } from "react-native";
import { Text } from "../Text";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { spacing } from "../../theme";

interface StatisticsProps {
  statistics: {
    value: string;
    icon: React.ReactNode;
  }[];
}

export function Statistics({ statistics }: StatisticsProps) {
  const theme = useBrandTheme();

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        borderColor: theme.colors.border,
        marginVertical: spacing.tiny,
      }}
    >
      {statistics.map((stat, index) => (
        <View
          key={index}
          style={{
            alignItems: "center",
            width: "25%",
            borderRightWidth: index % 4 === 3 ? 0 : 1,
            borderColor: theme.colors.border,
            justifyContent: "center",
            borderBottomWidth:
              statistics.length % 4 === 0 && index >= statistics.length - 4
                ? 0
                : 1,
            padding: spacing.micro,
          }}
        >
          {stat.icon}
          <Text
            size="xs"
            preset="formLabel"
            style={{
              textAlign: "center",
            }}
          >
            {stat.value}
          </Text>
        </View>
      ))}
    </View>
  );
}
