import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Card } from "../Card";
import { Text } from "../Text";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { CENTER_MIDDLE, CONTAINER } from "../../theme/view-style";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { useOrientation } from "../../utils/orientation-style";
import { observer } from "mobx-react-lite";

interface RaceGraphCardProps {
  title: string;
  subtitle: string;
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  children: React.ReactNode;
  metrics: React.ReactNode;
}

export const RaceGraphCard = observer(function RaceGraphCard({
  title,
  subtitle,
  isFullscreen,
  toggleFullscreen,
  children,
  metrics,
}: RaceGraphCardProps) {
  const deviceOrientation = useOrientation();
  const theme = useBrandTheme();

  return (
    <Card padding={true}>
      <View style={CONTAINER(deviceOrientation)}>
        <View style={{ ...CENTER_MIDDLE, justifyContent: "space-between" }}>
          <Text preset="subheading">{title}</Text>
          <TouchableOpacity onPress={toggleFullscreen}>
            <MaterialCommunityIcons
              name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
              size={25}
              color={theme.colors.text}
            />
          </TouchableOpacity>
        </View>
        <Text size="xxs" style={{ color: theme.colors.textDim }}>
          {subtitle}
        </Text>
      </View>
      {children}
      <View style={CONTAINER(deviceOrientation)}>{metrics}</View>
    </Card>
  );
});
