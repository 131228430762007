import { observer } from "mobx-react-lite";
import { Race } from "../../models/race/race";
import {
  Platform,
  ScrollView,
  ScrollViewProps,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";
import {
  CENTER_MIDDLE,
  CONTAINER,
  SMALL_BUTTON,
  SMALL_BUTTON_TEXT,
} from "../../theme/view-style";
import { Button, Text } from "../../components";
import { RaceCalculator } from "../../components/race-calculator/RaceCalculator";
import React, { ComponentType } from "react";
import { useOrientation } from "../../utils/orientation-style";
import { palette, spacing } from "../../theme";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";
import { ReminderRace } from "./ReminderRace";
import { translate } from "swunitch-i18n";
import { useStores } from "../../models";
import { alertMessage } from "../../services/AlertMessage";
import { openUrl } from "../../services/openUrl";

export const SEE_BOTTOM_SHADOW: ViewStyle = {
  paddingBottom: spacing.large,
  marginBottom: -spacing.large,
};

export const ListRaces = observer(function ListRaces(props: {
  title: string;
  races: Race[];
  computeTime: boolean;
  displayFilters?: boolean;
  isReminder?: boolean;
  carousel?: boolean;
  isFavorites?: boolean;
  displayMap?: boolean;
}) {
  const {
    races,
    title,
    computeTime,
    displayFilters = false,
    isReminder = false,
    carousel = false,
    displayMap = true,
    isFavorites,
  } = props;
  const deviceOrientation = useOrientation();
  const theme = useBrandTheme();
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const layout = useWindowDimensions();
  const { globalStore } = useStores();
  const nbOfFiltersNotEmpty = displayFilters
    ? globalStore.search.nbOfFilters
    : 0;

  const Wrapper = (
    carousel ? ScrollView : View
  ) as ComponentType<ScrollViewProps>;

  return races.length === 0 ? null : (
    <View>
      <View
        style={{
          ...CONTAINER(deviceOrientation),
          ...CENTER_MIDDLE,
          justifyContent: "space-between",
        }}
      >
        <Text preset="heading">{title}</Text>

        <View style={{ ...CENTER_MIDDLE, gap: spacing.small }}>
          {displayMap ? (
            <MaterialCommunityIcons
              name="map"
              size={24}
              color={theme.colors.text}
              onPress={() => {
                navigation.navigate("map", { races });
              }}
            />
          ) : null}
          {displayFilters ? (
            <View>
              <MaterialCommunityIcons
                name="tune-vertical"
                size={24}
                color={
                  nbOfFiltersNotEmpty === 0
                    ? theme.colors.text
                    : theme.colors.primary
                }
                onPress={() => {
                  navigation.navigate("races");
                }}
              />
              {nbOfFiltersNotEmpty > 0 ? (
                <View
                  style={{
                    position: "absolute",
                    top: -5,
                    right: -8,
                    backgroundColor: theme.colors.primary,
                    borderRadius: 10,
                    width: 18,
                    height: 18,
                  }}
                >
                  <Text
                    style={{ color: palette.neutral100, textAlign: "center" }}
                    size="xxs"
                  >
                    {nbOfFiltersNotEmpty}
                  </Text>
                </View>
              ) : null}
            </View>
          ) : null}
          <Button
            style={{ ...SMALL_BUTTON, borderColor: theme.colors.primary }}
            textStyle={[SMALL_BUTTON_TEXT, { color: theme.colors.primary }]}
            preset="outlined"
            onPress={() =>
              navigation.navigate("races", displayFilters ? {} : { races })
            }
            RightAccessory={() => (
              <Feather
                name="chevron-right"
                size={15}
                color={theme.colors.primary}
                style={{ marginRight: -spacing.tiny }}
              />
            )}
          >
            {translate("runScreen.seeAll")}
          </Button>
        </View>
      </View>

      <Wrapper
        style={carousel ? SEE_BOTTOM_SHADOW : {}}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        {isReminder ? (
          <Text
            preset="formLabel"
            tx="racesScreen.addRace"
            onPress={() => {
              const helpUrl = "https://pacevisor.com/new?nav=false";
              if (Platform.OS === "web") {
                openUrl(helpUrl);
              } else {
                navigation.navigate("help", {
                  url: helpUrl,
                  title: translate("new.addRace"),
                });
              }
            }}
            style={{ ...SMALL_BUTTON_TEXT, textAlign: "center" }}
          />
        ) : null}
        {races
          .filter((_, i) => i < 15)
          .map((race, i) => {
            return (
              <View key={race.id}>
                {isReminder && i === 1 && <ReminderRace />}
                <RaceCalculator
                  isFavorite={isFavorites}
                  race={race}
                  computeTime={computeTime}
                  onClose={
                    race.isLocal
                      ? () => {
                          alertMessage(
                            "editRaceScreen.removeTitle",
                            "editRaceScreen.removeDescription",
                          ).then(() => {
                            globalStore.removeLocalRace(race);
                          });
                        }
                      : undefined
                  }
                  style={
                    carousel
                      ? { width: layout.width / 1.3, alignSelf: "flex-start" }
                      : {}
                  }
                />
              </View>
            );
          })}
      </Wrapper>
    </View>
  );
});
