import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  RefreshControl,
  ScrollView,
  useWindowDimensions,
  View,
} from "react-native";
import { observer } from "mobx-react-lite";
import { CONTAINER } from "../../theme/view-style";
import { Button, Card, Screen, Text } from "../index";
import { spacing } from "../../theme";
import { AppStackParamList } from "../../navigators";
import { Platform } from "expo-modules-core";
import { Foundation, MaterialCommunityIcons } from "@expo/vector-icons";
import { useStores } from "../../models";
import { GeoJsonSegmenter } from "../../services/GeoJsonSegmenter";
import {
  EnhancedSegment,
  GeoJsonEnhancer,
} from "../../services/GeoJsonEnhancer";
import { GeoJsonMetadata, Metadata } from "../../services/GeoJsonMetadata";
import { Race } from "../../models/race/race";
import { translate } from "swunitch-i18n";
import { useDebounceEffect } from "ahooks";
import { useOrientation } from "../../utils/orientation-style";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { MinPerKilometer } from "../../domain/speed-converters/MinPerKilometer";
import { getPaceUnit, getSpeedUnit } from "../../utils/humanUnit";
import { formatNumber } from "../../utils/formatNumber";
import { StackNavigationProp } from "@react-navigation/stack";
import { Sport } from "../../models/sport/sport";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { Statistics } from "./Statistics";
import { RaceGraphs } from "./RaceGraphs";
import { RaceMap } from "./RaceMap";
import { defaultSegment } from "./defaultSegment";
import { RaceTitle } from "./RaceTitle";
import { useConverterBottomSheet } from "../bottom-sheet/ConverterBottomSheetContext";
import { GpxExporter } from "../../services/GpxExporter";
import { raceTable, RaceTable, RaceTableHeader } from "./RaceTable";
import { RaceCTA } from "./RaceCTA";
import { RaceForecast } from "./RaceForecast";
import { RaceType } from "../../models/race/race-type";

interface RaceAnalysisProps {
  race: Race;
  isInIframe: boolean;
}

const HEIGHT_WHEN_IN_IFRAME = 600;

export const RaceAnalysis = observer(function RaceAnalysis(
  props: RaceAnalysisProps,
) {
  const { race, isInIframe } = props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const [selectedSegment, setSelectedSegment] =
    useState<EnhancedSegment>(defaultSegment);
  const { globalStore } = useStores();
  const {
    setOptions,
    maximize,
    isOpen: isOpenFromBottomSheet,
  } = useConverterBottomSheet();
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [totalHeight, setTotalHeight] = useState(0);
  const layout = useWindowDimensions();
  const deviceOrientation = useOrientation();
  const [segments, setSegments] = useState<EnhancedSegment[]>([]);
  const [ghost, setGhost] = useState<string>("");
  const [profile, setProfile] = useState<EnhancedSegment[]>([]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [canScroll, setCanScroll] = useState(true);
  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(race.type as RaceType),
    [race.type],
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      window.parent.postMessage(
        {
          type: "setHeight",
          id: race.id,
          height: isBottomSheetOpen ? HEIGHT_WHEN_IN_IFRAME : totalHeight + 80,
        },
        "*",
      );
    }
  }, [totalHeight, isBottomSheetOpen]);

  useEffect(() => {
    if (!isOpenFromBottomSheet) {
      setTimeout(() => {
        // NOTE: bottom sheet struggle in tall screen, so I prefer to handle it manually and to only trigger the closing event from the bottom sheet
        setIsBottomSheetOpen(false);
      }, 1000);
    }
  }, [isOpenFromBottomSheet]);

  useFocusEffect(
    useCallback(() => {
      setOptions({
        type: race.type as RaceType,
        mode: "race",
      });
    }, [race.type]),
  );

  useEffect(() => {
    navigation.setOptions({
      headerShown: !isInIframe,
      title: race.title,
    });
  }, [isInIframe, race]);

  useEffect(() => {
    if (race && typeof race.fetchGeoJson === "function") {
      race.fetchGeoJson();
    }
  }, [race]);

  useDebounceEffect(
    () => {
      if (race.geoJson && typeof race.fetchGeoJson === "function") {
        setIsLoading(true);
        const geoJson = race.geoJsonParsed!;
        const geoJsonSegmenter = new GeoJsonSegmenter(geoJson);

        const segments = geoJsonSegmenter.segmentTrail(1000);
        const profile = geoJsonSegmenter.segmentTrail(500);
        const ghost = geoJsonSegmenter.segmentTrail(50);

        const enhancedSegments = new GeoJsonEnhancer(
          segments,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
          sport.isTimeObjective,
        ).enhanceSegments();
        const enhancedProfile = new GeoJsonEnhancer(
          profile,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
          sport.isTimeObjective,
        ).enhanceSegments();
        const enhancedGhost = new GeoJsonEnhancer(
          ghost,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
          sport.isTimeObjective,
        ).enhanceSegments();
        const metadata = new GeoJsonMetadata(geoJson).get();

        setGhost(GpxExporter.toGpx(race.title, enhancedGhost));
        setProfile(enhancedProfile);
        setSegments(enhancedSegments);
        setMetadata(metadata);
        setIsLoading(false);
      }
    },
    [
      race.geoJson,
      sport.speed,
      sport.effort,
      sport.descenderAbility,
      sport.climberAbility,
      sport.weight,
      sport.isTimeObjective,
    ],
    {
      wait: 500,
    },
  );

  const isLargeScreen = layout.width > 900;
  const finalTime = TimeConverter.toHuman(
    TimeConverter.fromSecondsToDomain(
      segments[segments.length - 1]?.cumulativeTime || 0,
    ),
  );

  return (
    <Screen
      hideFooter={isInIframe}
      preset="scroll"
      backgroundColor={Platform.OS === "web" ? colors.transparent : undefined}
      ScrollViewProps={{
        scrollEnabled: canScroll,
        refreshControl: (
          <RefreshControl refreshing={isLoading} onRefresh={() => null} />
        ),
      }}
    >
      <View
        onLayout={(e) => {
          setTotalHeight(e.nativeEvent.layout.height);
        }}
        style={
          isInIframe && isBottomSheetOpen
            ? {
                height: HEIGHT_WHEN_IN_IFRAME,
              }
            : {}
        }
      >
        <View
          style={{
            flexDirection: isLargeScreen ? "row" : "column",
            justifyContent: "space-between",
          }}
        >
          <View
            style={
              isLargeScreen ? { flex: 1, justifyContent: "space-between" } : {}
            }
          >
            <Card padding={true}>
              <RaceTitle race={race} />
              <Statistics
                statistics={[
                  {
                    value: finalTime,
                    icon: (
                      <MaterialCommunityIcons
                        name="clock-outline"
                        size={20}
                        color={theme.colors.text}
                      />
                    ),
                  },
                  {
                    value: translate("raceScreen.totalDistance", {
                      distance: formatNumber(metadata?.totalLength || 0),
                    }),
                    icon: (
                      <MaterialCommunityIcons
                        name="arrow-left-right"
                        size={20}
                        color={theme.colors.text}
                      />
                    ),
                  },

                  {
                    value: translate("raceScreen.pace", {
                      value: MinPerKilometer.fromMeterPerHour(
                        sport.domainSpeed.getSpeedWithEffort(),
                      ),
                      unit: getPaceUnit(globalStore.isMetricSystem),
                    }),
                    icon: (
                      <MaterialCommunityIcons
                        name="speedometer-medium"
                        size={20}
                        color={theme.colors.text}
                      />
                    ),
                  },
                  {
                    value: translate("raceScreen.pace", {
                      value: formatNumber(
                        KilometerPerHour.fromMeterPerHour(
                          sport.domainSpeed.getSpeedWithEffort(),
                        ),
                      ),
                      unit: getSpeedUnit(globalStore.isMetricSystem),
                    }),
                    icon: (
                      <MaterialCommunityIcons
                        name="speedometer"
                        size={20}
                        color={theme.colors.text}
                      />
                    ),
                  },
                  {
                    value: translate("raceScreen.elevation", {
                      value: formatNumber(
                        segments[segments.length - 1]
                          ?.cumulativeElevationGain || 0,
                      ),
                    }),
                    icon: (
                      <MaterialCommunityIcons
                        name="arrow-top-right"
                        size={20}
                        color={theme.colors.text}
                      />
                    ),
                  },
                  {
                    value: translate("raceScreen.elevation", {
                      value: formatNumber(
                        segments[segments.length - 1]
                          ?.cumulativeElevationLoss || 0,
                      ),
                    }),
                    icon: (
                      <MaterialCommunityIcons
                        name="arrow-bottom-right"
                        size={20}
                        color={theme.colors.text}
                      />
                    ),
                  },
                  {
                    value: `${translate("raceScreen.elevation", {
                      value: formatNumber(metadata?.highestPoint || 0),
                    })} / ${translate("raceScreen.elevation", {
                      value: formatNumber(metadata?.lowestPoint || 0),
                    })}`,
                    icon: (
                      <View style={{ flexDirection: "row" }}>
                        <Foundation
                          name="mountains"
                          size={20}
                          color={theme.colors.text}
                        />
                        <View style={{ marginLeft: 2 }}>
                          <Text
                            style={{
                              color: theme.colors.text,
                              lineHeight: 10,
                              fontSize: 10,
                            }}
                          >
                            max
                          </Text>
                          <Text
                            style={{
                              color: theme.colors.text,
                              lineHeight: 10,
                              fontSize: 10,
                            }}
                          >
                            min
                          </Text>
                        </View>
                      </View>
                    ),
                  },
                  {
                    value: translate("raceScreen.elevation", {
                      value: formatNumber(metadata?.averageAltitude || 0),
                    }),
                    icon: (
                      <View style={{ flexDirection: "row" }}>
                        <Foundation
                          name="mountains"
                          size={20}
                          color={theme.colors.text}
                        />
                        <Text size="xxs" style={{ color: theme.colors.text }}>
                          {translate("raceScreen.elevationAvg")}
                        </Text>
                      </View>
                    ),
                  },
                ]}
              />

              <View style={CONTAINER(deviceOrientation)}>
                <Button
                  onPress={() => {
                    setIsBottomSheetOpen(true);

                    setTimeout(
                      () => {
                        maximize({});
                      },
                      isInIframe ? 1000 : 0,
                    );
                  }}
                  style={{
                    backgroundColor: race.backgroundColor || colors.primary,
                  }}
                  textStyle={{
                    color: race.textColor || colors.text,
                  }}
                >
                  {translate("raceScreen.editMyInfo")}
                </Button>

                <Text
                  tx="tourGuide.raceTimeSmall"
                  txOptions={{ time: finalTime }}
                  size="xxs"
                  style={{
                    color: theme.colors.textDim,
                    marginTop: spacing.tiny,
                  }}
                />
              </View>
            </Card>

            <Card>
              <RaceCTA race={race} ghost={ghost} />
            </Card>
          </View>

          <View style={isLargeScreen ? { flex: 1 } : {}}>
            <Card>
              <RaceMap
                race={race}
                selectedSegment={selectedSegment}
                metadata={metadata}
                profile={profile}
                onMoveStart={() => setCanScroll(false)}
                onMoveEnd={() => setCanScroll(true)}
              />
            </Card>

            {race.forecasts.length > 0 && (
              <Card padding={true}>
                <RaceForecast race={race} />
              </Card>
            )}
          </View>
        </View>

        <RaceGraphs
          race={race}
          profile={profile}
          segments={segments}
          onSegmentSelected={(segment) => setSelectedSegment(segment)}
          isLargeScreen={isLargeScreen}
        />

        <Card
          padding={true}
          isLast={true}
          style={{ marginBottom: spacing.medium }}
        >
          <View style={CONTAINER(deviceOrientation)}>
            <Text preset="subheading">Roadbook</Text>
            <Text
              tx="raceScreen.roadbookExplanation"
              size="xxs"
              style={{
                color: theme.colors.textDim,
              }}
            />
          </View>

          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
            }}
            horizontal={true}
            nestedScrollEnabled={true}
            bounces={false}
          >
            <View style={{ ...raceTable, flex: 1 }}>
              <RaceTableHeader />
              <RaceTable segments={segments} seeAll={!isInIframe} />
            </View>
          </ScrollView>
        </Card>
      </View>
    </Screen>
  );
});
