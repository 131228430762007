import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { View } from "react-native";
import { MinPerKilometerComponent } from "../bottom-sheet/MinPerKilometerComponent";
import { KilometerPerHourComponent } from "../bottom-sheet/KilometerPerHourComponent";
import { EffortComponent } from "../bottom-sheet/EffortComponent";
import { useStores } from "../../models";
import { Sport } from "../../models/sport/sport";
import { spacing } from "../../theme";
import { MoreOptions } from "../../screens/run/MoreOptions";
import { ObjectiveModeSegmented } from "../segmented/ObjectiveModeSegmented";
import { RaceType } from "../../models/race/race-type";

export const RunComponent = observer(function RunComponent({
  hideMoreOptions,
}: {
  hideMoreOptions: boolean;
}) {
  const { globalStore } = useStores();
  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(RaceType.Run),
    [],
  );

  return (
    <View style={{ gap: spacing.medium }}>
      {hideMoreOptions ? null : <ObjectiveModeSegmented sport={sport} />}

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <MinPerKilometerComponent sport={sport} />

        <KilometerPerHourComponent sport={sport} />
      </View>

      <EffortComponent
        effort={sport.effort}
        onEffortChange={(e) => sport.updateEffort(e)}
      />

      {hideMoreOptions ? null : <MoreOptions type={RaceType.Run} />}
    </View>
  );
});
