import { Platform, TextStyle, View, ViewStyle } from "react-native";
import React, { useMemo, useState } from "react";
import {
  CENTER_MIDDLE,
  PRIMARY_FONT,
  PRIMARY_TEXT,
  RACE_COLUMN,
  RACE_OPTIONS,
} from "../../theme/view-style";
import { Race } from "../../models/race/race";
import { DistanceThumbnail } from "./DistanceThumbnail";
import { getRaceDomainDistance, Sport } from "../../models/sport/sport";
import { Text } from "../Text";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { observer } from "mobx-react-lite";
import { useDebounceEffect } from "ahooks";
import { palette, spacing } from "../../theme";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useStores } from "../../models";
import { translate } from "swunitch-i18n";
import { Entypo } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { Button } from "../Button";
import { RaceType } from "../../models/race/race-type";
import { alertMessage } from "../../services/AlertMessage";

interface RaceCalculatorProps {
  race: Race;
  computeTime: boolean;
  style?: ViewStyle;
  isFavorite?: boolean;
  onClose?: () => void;
}

const shadow: ViewStyle = {
  shadowColor: palette.neutral900,
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 0.5,
  shadowRadius: 3,
  elevation: 3,
};

export const RaceCalculator = observer(function RaceCalculator(
  props: RaceCalculatorProps,
) {
  const {
    race,
    computeTime = true,
    style,
    isFavorite = false,
    onClose,
  } = props;
  const { globalStore } = useStores();
  const textColorStyle: TextStyle = isFavorite
    ? {
        color: palette.neutral100,
      }
    : {};
  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(race.type as RaceType),
    [race.type],
  );
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const theme = useBrandTheme();
  const [humanTime, setHumanTime] = useState("");
  const distance = getRaceDomainDistance(race, globalStore.isMetricSystem);

  const start = () => {
    alertMessage("social.help", "tourGuide.raceTime");
  };

  useDebounceEffect(
    () => {
      if (race.geoJson && computeTime) {
        const time = race.getTime(sport);
        if (time) {
          setHumanTime(TimeConverter.toHuman(time));
        } else {
          setHumanTime("");
        }
      }
    },
    [
      race.geoJson,
      sport.speed,
      sport.effort,
      sport.descenderAbility,
      sport.climberAbility,
      sport.weight,
      sport.isTimeObjective,
    ],
    {
      wait: 500,
    },
  );

  return (
    <DistanceThumbnail
      race={race}
      style={style}
      isFavorite={isFavorite}
      onClose={onClose}
      floatingOptions={
        race.isLocal ? null : (
          <View
            style={{
              position: "absolute",
              right: 0,
              top: -17,
              gap: spacing.extraSmall,
              marginRight: spacing.small,
              ...CENTER_MIDDLE,
            }}
          >
            <View style={shadow}>
              <Button
                preset="rounded"
                onPress={() => globalStore.toggleFavoriteRace(race.id)}
                LeftAccessory={(props) => (
                  <FontAwesome
                    name={
                      globalStore.isFavoriteRace(race.id) ? "heart" : "heart-o"
                    }
                    size={16}
                    color={
                      props.pressableState.pressed
                        ? palette.neutral100
                        : theme.colors.primary
                    }
                  />
                )}
              />
            </View>
          </View>
        )
      }
      distanceContent={
        <Text
          testID="distance"
          style={{
            ...PRIMARY_FONT,
            ...textColorStyle,
          }}
        >
          {distance.value + translate(`units.${distance.humanDistanceUnit()}`)}
        </Text>
      }
      content={
        <>
          <View style={RACE_COLUMN}>
            {humanTime && (
              <>
                <Text
                  style={{
                    ...PRIMARY_TEXT(theme),
                  }}
                  testID="humanTime"
                  onPress={() =>
                    navigation.navigate("race", {
                      race: Platform.OS === "web" ? race.id : race,
                    })
                  }
                >
                  {humanTime}
                </Text>
                <Entypo
                  size={15}
                  name="help-with-circle"
                  color={theme.colors.primary + "AA"}
                  style={{
                    padding: spacing.micro,
                    paddingLeft: spacing.small,
                    alignSelf: "center",
                    opacity: 0.7,
                  }}
                  onPress={() => start()}
                />
              </>
            )}
          </View>
          <View style={RACE_OPTIONS} />
        </>
      }
    />
  );
});
