import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import BottomSheet, {
  BottomSheetBackdrop,
  BottomSheetBackgroundProps,
  BottomSheetHandle,
  BottomSheetProps,
  BottomSheetScrollView,
} from "@gorhom/bottom-sheet";
import { useOrientation } from "../../utils/orientation-style";
import { Platform } from "expo-modules-core";
import {
  ImageBackground,
  Keyboard,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { observer } from "mobx-react-lite";
import { CONTAINER } from "../../theme/view-style";
import { palette, spacing } from "../../theme";
import { Button } from "../Button";
import { RaceType } from "../../models/race/race-type";
import { SportsComponent } from "../sports/SportsComponent";
import { imageRegistry } from "../Icon";

interface Options {
  type?: RaceType | "all";
  mode: "training" | "race";
}

export const SNAP_POINTS = ["17%", "40%", 500] as const;

const ConverterBottomSheetContext = createContext<{
  maximize: ({
    position,
    type,
  }: {
    position?: number | string;
    type?: RaceType | "all";
  }) => void;
  minimize: () => void;
  setOptions: (options: Options) => void;
  type: RaceType | "all";
  isOpen: boolean;
}>({
  maximize: () => null,
  minimize: () => null,
  setOptions: () => ({
    type: RaceType.Run,
    mode: "home",
    wait: false,
  }),
  type: RaceType.Run,
  isOpen: false,
});

export const ConverterBottomSheetProvider = observer(function ({
  children,
}: {
  children: React.ReactNode;
}) {
  const bottomSheetRef = useRef<BottomSheet>(null);
  const [type, setType] = useState<RaceType | "all">(RaceType.Run);
  const [bottomSheetProps, setBottomSheetProps] =
    useState<Partial<BottomSheetProps> | null>(null);
  const [bottomSheetIndex, setBottomSheetIndex] = useState(-1);
  const deviceOrientation = useOrientation();
  const [hideMoreOptions, setHideMoreOptions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const layout = useWindowDimensions();

  const BottomScrollViewForWeb =
    Platform.OS === "web" ? ScrollView : BottomSheetScrollView;

  function closeBottomSheet() {
    setIsOpen(false);
    bottomSheetRef.current?.close();
    if (bottomSheetIndex === 1) {
      Keyboard.dismiss();
    }
  }

  useEffect(() => {
    setIsOpen(bottomSheetIndex > -1);
  }, [bottomSheetIndex]);

  const maximize = ({
    position,
    type,
  }: {
    position?: number | string;
    type?: RaceType | "all";
  }) => {
    if (bottomSheetRef && bottomSheetRef.current) {
      if (position === undefined) {
        // NOTE: maximum position
        bottomSheetRef.current.snapToPosition(SNAP_POINTS[2]);
      } else {
        bottomSheetRef.current.snapToPosition(position);
      }

      if (type) {
        setType(type);
      }
    }
  };

  const setOptions = ({ type, mode }: Options) => {
    if (type) {
      setType(type);
    }

    setBottomSheetProps({
      enablePanDownToClose: true,
      ...(Platform.OS === "web" && {
        backdropComponent: null,
      }),
    });

    if (mode === "race") {
      setHideMoreOptions(false);
    } else if (mode === "training") {
      setHideMoreOptions(true);
    }

    closeBottomSheet();
  };

  const background = useCallback(
    (props: BottomSheetBackgroundProps) => (
      <ImageBackground
        source={imageRegistry.blueBackground}
        {...props}
        style={{
          ...StyleSheet.absoluteFillObject,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
      />
    ),
    [],
  );

  return (
    <ConverterBottomSheetContext.Provider
      value={{
        maximize,
        minimize: closeBottomSheet,
        setOptions,
        type,
        isOpen,
      }}
    >
      {children}

      <BottomSheet
        ref={bottomSheetRef}
        index={bottomSheetIndex}
        onChange={setBottomSheetIndex}
        snapPoints={SNAP_POINTS}
        enablePanDownToClose={true}
        enableOverDrag={true}
        keyboardBlurBehavior="restore"
        backgroundComponent={background}
        onClose={closeBottomSheet}
        handleIndicatorStyle={{
          backgroundColor: palette.neutral100,
        }}
        containerStyle={{
          marginHorizontal: layout.width > 500 ? (layout.width - 500) / 2 : 0,
        }}
        backdropComponent={(props) => <BottomSheetBackdrop {...props} />}
        handleComponent={(props) => (
          <TouchableOpacity onPress={() => maximize({})}>
            <BottomSheetHandle {...props} />
          </TouchableOpacity>
        )}
        {...bottomSheetProps}
      >
        <BottomScrollViewForWeb style={CONTAINER(deviceOrientation)}>
          <SportsComponent
            type={type}
            setType={setType}
            hideMoreOptions={hideMoreOptions}
          />

          {hideMoreOptions ? null : (
            <Button
              style={{ marginTop: spacing.medium, marginBottom: 50 }}
              onPress={closeBottomSheet}
              tx="common.ok"
            />
          )}
        </BottomScrollViewForWeb>
      </BottomSheet>
    </ConverterBottomSheetContext.Provider>
  );
});

export const useConverterBottomSheet = () => {
  return useContext(ConverterBottomSheetContext);
};
