import React, { useMemo } from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { Race } from "../../models/race/race";
import { Text } from "../Text";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { CONTAINER } from "../../theme/view-style";
import { useOrientation } from "../../utils/orientation-style";
import { Statistics } from "./Statistics";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { formatNumber } from "../../utils/formatNumber";
import { degreeToOrientation } from "../../services/degreeToOrientation";

export const RaceForecast = observer(function RaceForecast(props: {
  race: Race;
}) {
  const { race } = props;
  const theme = useBrandTheme();
  const deviceOrientation = useOrientation();

  const averageForecast = useMemo(() => {
    const totalForecasts = race.forecasts.length;
    const totalTemperature = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.temperature || 0),
      0,
    );
    const totalPrecipitation = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.precipitation || 0),
      0,
    );
    const totalWindSpeed = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.windSpeed?.ele10m || 0),
      0,
    );
    const totalWindDirection = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.windDirection?.ele10m || 0),
      0,
    );

    return {
      averageTemperature: totalTemperature / totalForecasts,
      averagePrecipitation: totalPrecipitation / totalForecasts,
      averageWindSpeed: (totalWindSpeed / totalForecasts) * 3.6,
      averageWindDirection: totalWindDirection / totalForecasts,
    };
  }, [race.forecasts]);

  return (
    <View>
      <View style={CONTAINER(deviceOrientation)}>
        <Text preset="subheading" tx="raceScreen.forecast" />
      </View>
      <Statistics
        statistics={[
          {
            value: `${formatNumber(averageForecast.averageTemperature)}°C`,
            icon: (
              <MaterialCommunityIcons
                name="thermometer"
                size={20}
                color={theme.colors.text}
              />
            ),
          },
          {
            value: `${formatNumber(averageForecast.averagePrecipitation)}mm`,
            icon: (
              <MaterialCommunityIcons
                name="weather-rainy"
                size={20}
                color={theme.colors.text}
              />
            ),
          },
          {
            value: `${formatNumber(averageForecast.averageWindSpeed)} km/h`,
            icon: (
              <MaterialCommunityIcons
                name="weather-windy"
                size={20}
                color={theme.colors.text}
              />
            ),
          },
          {
            value: `${degreeToOrientation(averageForecast.averageWindDirection)}`,
            icon: (
              <FontAwesome
                name="arrow-up"
                size={20}
                color={theme.colors.text}
                style={{
                  transform: [
                    {
                      rotate: `${averageForecast.averageWindDirection - 180}deg`,
                    },
                  ],
                }}
              />
            ),
          },
        ]}
      />
    </View>
  );
});
