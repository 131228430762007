import { observer } from "mobx-react-lite";
import { TouchableOpacity, View, ViewStyle } from "react-native";
import React, { useState } from "react";
import { Text } from "../../Text";
import { TimeConverter } from "../../../domain/race-calculator/TimeConverter";
import { CENTER_MIDDLE } from "../../../theme/view-style";
import { AppDarkTheme, palette, spacing } from "../../../theme";
import { TimeEdition } from "../../race-calculator/TimeEdition";
import { useStores } from "../../../models";
import { useTriathlonHook } from "./TriathlonHook";
import { Card } from "../../Card";
import { AppLightTheme } from "../../../theme/colors-light";
import { useBrandTheme } from "../../../theme/use-brand-theme";
import { RaceType } from "../../../models/race/race-type";

export const Timeline = observer(function Timeline(props: {
  onChangeType: (type: RaceType) => void;
}) {
  const { onChangeType } = props;
  const { globalStore } = useStores();
  const theme = useBrandTheme();
  const {
    timeForRun,
    timeForBike,
    timeForBikeTransition,
    timeForSwim,
    timeForSwimTransition,
    fullTime,
  } = useTriathlonHook();
  const [isSwimTransitionInEdition, setSwimTransitionInEdition] =
    useState(false);
  const [isBikeTransitionInEdition, setBikeTransitionInEdition] =
    useState(false);

  const firstCard: ViewStyle = {
    backgroundColor: palette.neutral100,
    flexGrow: 1,
    margin: 0,
    shadowOpacity: 0,
  };

  const secondCard: ViewStyle = {
    backgroundColor: palette.neutral100,
    flexGrow: 1,
    margin: 0,
    shadowOpacity: 0,
  };

  return (
    <View style={{ marginTop: spacing.small, gap: spacing.extraSmall }}>
      <View style={{ ...CENTER_MIDDLE, gap: spacing.extraSmall }}>
        {isSwimTransitionInEdition ? null : (
          <Card style={firstCard}>
            <View style={{ margin: spacing.extraSmall, marginTop: 0 }}>
              <Text
                preset="subheading"
                tx="sports.swim"
                style={{ color: AppLightTheme.colors.text }}
              />

              <TouchableOpacity onPress={() => onChangeType(RaceType.Swim)}>
                <TimeEdition
                  layout="big"
                  textColor={AppLightTheme.colors.text}
                  time={timeForSwim}
                  onTimeChange={(t) =>
                    globalStore.sport.swim.updateTransitionTime(
                      TimeConverter.toSeconds(t),
                    )
                  }
                  backgroundColor={palette.secondary500 + "66"}
                  disabled={true}
                />
              </TouchableOpacity>
            </View>
          </Card>
        )}

        <Card style={secondCard}>
          <View style={{ margin: spacing.extraSmall, marginTop: 0 }}>
            <Text
              preset="subheading"
              tx="sports.transition"
              style={{ color: AppLightTheme.colors.text }}
            />

            <TimeEdition
              layout="big"
              textColor={AppLightTheme.colors.text}
              backgroundColor={palette.secondary500 + "66"}
              time={timeForSwimTransition}
              onInEdition={setSwimTransitionInEdition}
              onTimeChange={(t) =>
                globalStore.sport.swim.updateTransitionTime(
                  TimeConverter.toSeconds(t),
                )
              }
            />
          </View>
        </Card>
      </View>

      <View style={{ ...CENTER_MIDDLE, gap: spacing.extraSmall }}>
        {isBikeTransitionInEdition ? null : (
          <Card style={firstCard}>
            <View style={{ margin: spacing.extraSmall, marginTop: 0 }}>
              <Text
                preset="subheading"
                tx="sports.bike"
                style={{ color: AppLightTheme.colors.text }}
              />

              <TouchableOpacity onPress={() => onChangeType(RaceType.Bike)}>
                <TimeEdition
                  layout="big"
                  textColor={AppLightTheme.colors.text}
                  time={timeForBike}
                  onTimeChange={(t) =>
                    globalStore.sport.bike.updateTransitionTime(
                      TimeConverter.toSeconds(t),
                    )
                  }
                  backgroundColor={palette.secondary500 + "66"}
                  disabled={true}
                />
              </TouchableOpacity>
            </View>
          </Card>
        )}

        <Card style={secondCard}>
          <View style={{ margin: spacing.extraSmall, marginTop: 0 }}>
            <Text
              preset="subheading"
              tx="sports.transition"
              style={{ color: AppLightTheme.colors.text }}
            />

            <TimeEdition
              layout="big"
              textColor={AppLightTheme.colors.text}
              backgroundColor={palette.secondary500 + "66"}
              time={timeForBikeTransition}
              onInEdition={setBikeTransitionInEdition}
              onTimeChange={(t) =>
                globalStore.sport.bike.updateTransitionTime(
                  TimeConverter.toSeconds(t),
                )
              }
            />
          </View>
        </Card>
      </View>

      <View style={{ ...CENTER_MIDDLE, gap: spacing.extraSmall }}>
        <Card style={firstCard}>
          <View style={{ margin: spacing.extraSmall, marginTop: 0 }}>
            <Text
              preset="subheading"
              tx="sports.run"
              style={{ color: AppLightTheme.colors.text }}
            />

            <TouchableOpacity onPress={() => onChangeType(RaceType.Run)}>
              <TimeEdition
                layout="big"
                textColor={AppLightTheme.colors.text}
                time={timeForRun}
                onTimeChange={(t) =>
                  globalStore.sport.run.updateTransitionTime(
                    TimeConverter.toSeconds(t),
                  )
                }
                backgroundColor={palette.secondary500 + "66"}
                disabled={true}
              />
            </TouchableOpacity>
          </View>
        </Card>

        <Card style={{ ...secondCard, backgroundColor: theme.colors.primary }}>
          <View style={{ marginBottom: spacing.extraSmall }}>
            <Text
              preset="subheading"
              tx="units.totalTime"
              style={{
                color: AppDarkTheme.colors.text,
                marginHorizontal: spacing.extraSmall,
              }}
            />

            <TimeEdition
              layout="big"
              textColor={AppDarkTheme.colors.text}
              time={fullTime}
              disabled={true}
              backgroundColor={theme.colors.primary}
              onTimeChange={() => null}
            />
          </View>
        </Card>
      </View>
    </View>
  );
});
