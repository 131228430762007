import React from "react";
import { observer } from "mobx-react-lite";
import { PRIMARY_SEMI_BOLD_FONT } from "../../theme/view-style";
import SegmentedControl from "react-native-segmented-control-2";
import { palette } from "../../theme";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { Sport } from "../../models/sport/sport";
import { translate } from "swunitch-i18n";
import { View } from "react-native";
import { useToast } from "react-native-toast-notifications";

export const ObjectiveModeSegmented = observer(
  function ObjectiveModeSegmented(props: { sport: Sport }) {
    const { sport } = props;
    const theme = useBrandTheme();
    const toast = useToast();

    const selectMode = (index: number) => {
      if (index === 0) {
        sport.updateIsTimeObjective(false);
        toast.show(translate("toast.distanceObjective"), {
          type: "success",
          duration: 8000,
          placement: "top",
          successColor: palette.secondary400,
        });
      } else {
        sport.updateIsTimeObjective(true);
        toast.show(translate("toast.timeObjective"), {
          type: "success",
          duration: 8000,
          placement: "top",
          successColor: palette.secondary400,
        });
      }
    };

    return (
      <View style={{ alignItems: "center" }}>
        <SegmentedControl
          onChange={selectMode}
          value={sport.isTimeObjective ? 1 : 0}
          textStyle={{ ...PRIMARY_SEMI_BOLD_FONT }}
          style={{
            borderRadius: 100,
            width: 270,
            marginBottom: -10,
          }}
          tabStyle={{
            borderRadius: 100,
          }}
          selectedTabStyle={{
            borderRadius: 100,
          }}
          tabs={[
            translate("segmented.distanceObjective"),
            translate("segmented.timeObjective"),
          ]}
          activeTabColor={theme.colors.primary}
          activeTextColor={palette.neutral100}
        />
      </View>
    );
  },
);
