import { observer } from "mobx-react-lite";
import { TextField } from "../../components";
import { spacing } from "../../theme";
import { translate } from "swunitch-i18n";
import { AbilitySlider } from "../../components/race-analysis/AbilitySlider";
import React, { useCallback, useEffect, useMemo } from "react";
import { Sport } from "../../models/sport/sport";
import { useStores } from "../../models";
import { Platform, View } from "react-native";
import { BOTTOM_SHEET_TEXT } from "../../theme/view-style";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { RaceType } from "../../models/race/race-type";

interface MoreOptionsProps {
  type: RaceType;
}

export const MoreOptions = observer(function MoreOptions(
  props: MoreOptionsProps,
) {
  const { type } = props;
  const { globalStore } = useStores();
  const bottomSheetInternal = useBottomSheetInternal(true);
  const shouldHandleKeyboardEvents =
    bottomSheetInternal?.shouldHandleKeyboardEvents || {
      value: true,
    };

  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(type),
    [type],
  );

  useEffect(() => {
    return () => {
      if (Platform.OS === "ios") {
        shouldHandleKeyboardEvents.value = false;
      }
    };
  }, [shouldHandleKeyboardEvents]);

  const handleOnFocus = useCallback(() => {
    if (Platform.OS === "ios") {
      shouldHandleKeyboardEvents.value = true;
    }
  }, [shouldHandleKeyboardEvents]);
  const handleOnBlur = useCallback(() => {
    if (Platform.OS === "ios") {
      shouldHandleKeyboardEvents.value = false;
    }
  }, [shouldHandleKeyboardEvents]);

  function updateWeight(text: string) {
    const w = Number(text);
    sport.updateWeight(w);
  }

  return (
    <View>
      {type === RaceType.Bike && (
        <TextField
          LabelTextProps={{
            size: "xs",
            style: BOTTOM_SHEET_TEXT,
            preset: "subheading",
          }}
          containerStyle={{
            marginBottom: spacing.extraSmall,
          }}
          label={translate("raceScreen.weight")}
          onChangeText={updateWeight}
          defaultValue={sport.weight.toString()}
          keyboardType="numeric"
          style={BOTTOM_SHEET_TEXT}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
      )}
      <AbilitySlider
        onValueChange={(v) => sport.updateClimberAbility(v)}
        value={sport.climberAbility}
        tx="raceScreen.climberNote"
      />

      <AbilitySlider
        onValueChange={(v) => sport.updateDescenderAbility(v)}
        value={sport.descenderAbility}
        tx="raceScreen.descenderNote"
      />
    </View>
  );
});
