import { Text } from "../Text";
import { translate, TxKeyPath } from "swunitch-i18n";
import { formatNumber } from "../../utils/formatNumber";
import { Slider } from "@miblanchard/react-native-slider";
import * as Haptics from "expo-haptics";
import React from "react";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { AntDesign } from "@expo/vector-icons";
import { Platform, View } from "react-native";
import { spacing } from "../../theme";
import { BOTTOM_SHEET_TEXT, CENTER_MIDDLE } from "../../theme/view-style";

export function AbilitySlider({
  onValueChange,
  value,
  tx,
}: {
  onValueChange: (number: number) => void;
  value: number;
  tx: TxKeyPath;
}) {
  const theme = useBrandTheme();
  const { colors } = theme;

  return (
    <>
      <View style={CENTER_MIDDLE}>
        <AntDesign
          name="reload1"
          size={15}
          onPress={() => onValueChange(1)}
          color={BOTTOM_SHEET_TEXT.color}
          style={{ marginRight: spacing.extraSmall, padding: spacing.micro }}
        />
        <Text preset="subheading" size="xs" style={BOTTOM_SHEET_TEXT}>
          {translate(tx)}
          {formatNumber(value)}
        </Text>
      </View>
      <View style={[CENTER_MIDDLE, { justifyContent: "space-between" }]}>
        <Text size="xxs" style={BOTTOM_SHEET_TEXT}>
          {translate("raceScreen.abilityNoteBad")}
        </Text>
        <Text size="xxs" style={BOTTOM_SHEET_TEXT}>
          {translate("raceScreen.abilityNoteGood")}
        </Text>
      </View>

      <Slider
        containerStyle={{ height: 30, marginTop: -10 }}
        minimumValue={0.2}
        maximumValue={1.8}
        trackClickable={true}
        step={0.01}
        value={value}
        onValueChange={(v) => {
          if (v[0] !== undefined) {
            if (Platform.OS !== "web") {
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            }
            onValueChange(v[0]);
          }
        }}
        minimumTrackTintColor={colors.primaryLight}
        maximumTrackTintColor={colors.primaryDarker}
        thumbTintColor={colors.primary}
      />
    </>
  );
}
