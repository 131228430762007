import { palette, spacing } from "../theme";
import { Button } from "./Button";
import { SMALL_BUTTON, SMALL_BUTTON_TEXT } from "../theme/view-style";
import { Ionicons } from "@expo/vector-icons";
import { View } from "react-native";
import React from "react";
import { RaceType } from "../models/race/race-type";

interface SportSelectorInterface {
  type: RaceType;
  onChange: (type: RaceType) => void;
}

export function SportSelector(props: SportSelectorInterface) {
  const { type, onChange } = props;
  return (
    <View
      style={{
        flexDirection: "row",
        gap: spacing.small,
        marginTop: spacing.tiny,
      }}
    >
      {Object.entries(RaceType).map(([key, value]) => (
        <Button
          key={key}
          preset={type === value ? "reversed" : "outlined"}
          style={{ ...SMALL_BUTTON, borderWidth: 1 }}
          textStyle={SMALL_BUTTON_TEXT}
          tx={`sports.${value}`}
          onPress={() => onChange(value)}
          RightAccessory={() =>
            type === value ? (
              <Ionicons
                name="close-circle"
                size={15}
                color={palette.neutral100}
                style={{ marginLeft: spacing.tiny }}
              />
            ) : null
          }
        />
      ))}
    </View>
  );
}
